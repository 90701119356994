import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [{
  path: '/',
  redirect: '/index' // 重定向
},
{
  path: "/index",
  name: "index",
  component: resolve => {
    require(['../views/main.vue'], resolve)
  },
},
]

const router = new VueRouter({
  mode: 'history', // 路由历史模式
  routes
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router